import PropTypes from 'prop-types';

import { contentfulRichTextType } from 'types';
import { contentfulImageType } from 'types/common/contentfulImage.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

export const profileCardsSectionProps = {
  backgroundColor: PropTypes.string,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  heading: PropTypes.string,
  body: contentfulRichTextType,
  authorsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        bio: contentfulRichTextType.isRequired,
        role: PropTypes.string,
        linkedIn: PropTypes.string,
        twitter: PropTypes.string,
        email: PropTypes.string,
        image: contentfulImageType.isRequired,
      })
    ),
  }),
};

export const profileCardsSectionType = profileCardsSectionProps;
