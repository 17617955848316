import PropTypes from 'prop-types';

import { basicMastheadType } from 'humanity/sections/basicMasthead/basicMasthead.types';
import { featuredValuePropType } from 'humanity/sections/featuredValueProp/featuredValueProp.types';
import { largePricingCardType } from 'humanity/sections/largePricingCard/largePricingCard.types';
import { numberedStepListType } from 'humanity/sections/numberedStepList/numberedStepList.types';
import { compactBannerType } from 'humanity/sections/compactBanner/compactBanner.types';
import { valuePropListType } from 'humanity/sections/valuePropList/valuePropList.types';
import { ctaBannerType } from 'humanity/sections/ctaBanner/ctaBanner.types';
import { testimonialsGridType } from 'humanity/sections/testimonialsGrid/testimonialsGrid.types';
import { featuredTestimonialType } from 'humanity/sections/featuredTestimonial/featuredTestimonial.types';
import { payrollIntegrationsType } from 'humanity/sections/payrollIntegrations/payrollIntegrations.types';
import { pricingCardsType } from 'humanity/sections/pricingCards/pricingCards.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { simpleRichTextSectionType } from 'humanity/sections/simpleRichTextSection/simpleRichTextSection.types';
import { fullWidthMediaType } from 'humanity/sections/fullWidthMedia/fullWidthMedia.types';
import { embeddedMediaType } from 'humanity/sections/embeddedMedia/embeddedMedia.types';
import { faqsType } from 'humanity/sections/faqs/faqs.types';
import { profileCardsSectionType } from 'humanity/sections/profileCardsSection';
import { imageTextBlockSectionType } from 'humanity/sections/imageTextBlockSection';

export const storefrontPageType = PropTypes.shape({
  sectionsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        basicMastheadType,
        compactBannerType,
        featuredValuePropType,
        largePricingCardType,
        numberedStepListType,
        valuePropListType,
        ctaBannerType,
        testimonialsGridType,
        featuredTestimonialType,
        testimonialsGridType,
        payrollIntegrationsType,
        pricingCardsType,
        fullWidthMediaType,
        embeddedMediaType,
        faqsType,
        simpleRichTextSectionType,
        profileCardsSectionType,
        imageTextBlockSectionType,
      ])
    ),
  }),
  notesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(simpleRichTextType),
  }),
  disclosuresCollection: PropTypes.shape({
    items: PropTypes.arrayOf(simpleRichTextType),
  }),
});
